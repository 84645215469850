import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalSettingsService {
    public static get<T>(key: string): T {
        try {
            const settings: any = JSON.parse(window.localStorage.getItem('settings')) || {};
            return settings[key] as T;
        } catch (_e) {
            return {} as T;
        }
    }

    public static set<T>(key: string, value: T): void {
        try {
            const settings: any = JSON.parse(window.localStorage.getItem('settings')) || {};
            settings[key] = value;
            window.localStorage.setItem('settings', JSON.stringify(settings));
        } catch (_e) {
            /* empty */
        }
    }
}
