import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PermissionService } from '@core/services/internal/permission.service';
import { SessionService } from '@core/services/internal/session.service';
import { User } from '@shared/models/user.model';

@Injectable()
export class UserSessionGuard implements CanActivate {
    constructor(
        private sessionService: SessionService,
        private router: Router,
        private permissionService: PermissionService
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const user: User = await this.sessionService.getUserByActivatedRouteSnapshot(route);

        // Make sure user is logged in.
        if (this.sessionService.isLoggedIn() && user) {
            this.permissionService.setUserRole(user.role.role);

            return true;
        }

        this.router.navigate(['/']);

        return false;
    }
}
