export enum ObjectType {
    BannerSet = 0,
    Feed = 1,
    Schedule = 2,
    LandingPage = 3,
    StudioCreativeSet = 4,
    Banner = 16,
    Brand = 32,
    Account = 64,
    Localization = 128,
    SizeFormat = 256,
    Version = 512,
    BannerFormat = 1024,
    Country = 2048,
    StudioCampaign = 4096,
    StudioAd = 8192,
    AdGroup = 16384
}
