import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUIDialogConfig, UIDialogRef, UIDialogService, UINotificationService } from '@bannerflow/ui';
import { ApiService, BFHttpError } from '@core/services/api/api.service';
import { SessionService } from '@core/services/internal/session.service';
import { Constants } from '@shared/constants';
import { Banner } from '@shared/models/banner/banner.model';
import { BannerSet } from '@shared/models/banner/bannerSet.model';
import { Comment } from '@shared/models/comment.model';
import { ApproveDialogComponent } from './approveDialog/approveDialog.component';

@Injectable()
export class CommentsService {
    private apiService: ApiService;
    private postUrl: string;

    constructor(
        apiService: ApiService,
        private readonly sessionService: SessionService,
        private notificationService: UINotificationService,
        private dialogService: UIDialogService
    ) {
        this.apiService = apiService;
        this.postUrl = `${Constants.URLS.STATIC}/comments`;
    }

    public postComment(comment: Comment): Promise<Comment> {
        const dataComment: any = comment as any;
        dataComment.userId = comment.user.id;
        dataComment.email = comment.user.email;

        return this.apiService.post<Comment>(this.postUrl, dataComment, { anonymous: true }).then((data: any) => {
            const getFirstName = (name: string): string => name.split(' ')[0];

            const getLastName = (name: string): string => {
                const arr: string[] = name.split(' ');
                if (arr.length > 1) {
                    return arr[1];
                } else {
                    return '';
                }
            };

            data.user = {
                id: data.userId,
                firstName: getFirstName(data.name),
                lastName: getLastName(data.name),
                email: data.email,
                profile: data.profile
            };

            return new Comment().deserialize(data);
        });
    }

    public deleteComment(comment: Comment): Promise<void> {
        const url = `${this.postUrl}/delete/comment`;

        return this.apiService.post<any>(url, comment, { anonymous: true }).then(
            _success => {},
            (err: BFHttpError) => {
                if (err.status === 400) {
                    const msg = (err.originalResponse as HttpErrorResponse).error || null;
                    if (msg && msg.message) {
                        this.notificationService.open(msg.message, {
                            type: 'error',
                            placement: 'top',
                            autoCloseDelay: 5000
                        });
                    }
                }

                return Promise.reject({});
            }
        );
    }

    // TODO: unwrap response and just return comment
    public toggleApprove(bannerSet: BannerSet, approve: boolean, banner: Banner = null): Promise<Comment> {
        const url = this.postUrl + (approve ? '' : '/delete');
        const requestBody = {
            email: this.sessionService.user.email,
            bannerSetId: bannerSet.id,
            adId: banner ? banner.id : null,
            approved: approve,
            text: banner ? 'Banner approved' : 'Banner set approved'
        };

        return this.apiService
            .post<any>(url, requestBody, { anonymous: true })
            .then((data: any) => {
                let comment: Comment = null;
                if (approve) {
                    const c = data;

                    // Ugly fix because of bad data model inconsistencies
                    c.user = {
                        id: c.userid,
                        firstname: c.name,
                        profile: c.profile
                    };

                    comment = new Comment().deserialize(c);
                }

                if (banner) {
                    banner.approved = comment;
                }

                return comment;
            })
            .catch((response: BFHttpError) => {
                // If no email is set for the anonymous user, prompt with input dialog.
                if (response.status === 400) {
                    return this.openApproveDialog(approve, bannerSet, banner);
                } else {
                    return Promise.reject(response);
                }
            });
    }

    private async openApproveDialog(approve: boolean, bannerSet: BannerSet, banner: Banner): Promise<any> {
        const dialogConfig: IUIDialogConfig = {
            headerText: 'Approve Dialog',
            maxWidth: '600px'
        };

        const dialogRef: UIDialogRef = this.dialogService.openComponent(ApproveDialogComponent, dialogConfig);
        await dialogRef.afterViewInit;

        const dialogResponse: any = await dialogRef.subComponentRef.instance.initiate();

        if (dialogResponse && !dialogResponse.cancel) {
            // Set the anonymous email address
            this.sessionService.setAnonymous(dialogResponse.data);

            // Try again and approve all
            this.toggleApprove(bannerSet, approve, banner);
        }

        dialogRef.close();
    }
}
