// Modules
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAuth0 } from '@auth0/auth0-angular';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { AppConfig } from '@config/app.config';
import { SignalRHubService } from '@core/services/api/signalr/signalr-hub.service';
import { TestSignalRHubService } from '@core/services/api/signalr/test-signalr-hub.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { FeatureFlagsService } from '@core/services/internal/feature-flag.service';
import { AppStoreModule } from '@core/store/app-store.module';
import { UtilPagesModule } from '@modules/util-pages/util-pages.module';
import { GlobalErrorHandler } from '@plugins/global-error-handler.service';
import { NewRelicPlugin } from '@plugins/new-relic.plugin';
import { getAuthConfig } from '@shared/auth0/auth0.config';
import { UserSessionGuard } from '@shared/guards/user-session.guard';
import { proxyAuthInterceptorFn } from '@shared/interceptors/proxy-auth.interceptor';
import { SharedModule } from '@shared/shared.module';
import { take } from 'rxjs';
import { config as environment } from '../lib/config/environment.dev';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { AppViewComponent } from './app.view.component';
import { AppService } from './core/services/internal/app.service';
import { graphqlProvider } from './graphql.provider';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        AppStoreModule,
        SharedModule,
        UtilPagesModule,
        APP_ROUTES,
        CdkTableModule,
        CdkTreeModule
    ],
    declarations: [AppComponent, AppViewComponent],
    providers: [
        AppService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: SignalRHubService,
            useClass: CookieService.get('IN_TEST') ? TestSignalRHubService : SignalRHubService
        },
        {
            provide: FeatureFlagsService,
            useFactory: (): FeatureFlagsService => {
                const service = new FeatureFlagsService();
                service.initialize({
                    enabled: environment.FEATURE_FLAGS.enabled,
                    url: environment.FEATURE_FLAGS.url,
                    clientKey: environment.FEATURE_FLAGS.clientKey,
                    appName: 'BannerflowClient'
                })
                service.initialized$.pipe(take(1)).subscribe(() => service.start())
                return service
            }
        },
        UserSessionGuard,
        provideSentinelService(
            withNewRelic({
                enabled: AppConfig.config.STAGE !== 'local',
                ...NewRelicPlugin.init(AppConfig.config.STAGE === 'production', [
                    AppConfig.config.origins.LIST_SERVICE_URL,
                    AppConfig.config.origins.STUDIO_URL
                ])
            })
        ),
        graphqlProvider,
        provideHttpClient(withInterceptors([proxyAuthInterceptorFn])),
        provideAuth0(getAuthConfig())
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
