/**
 * Mocks {@link https://github.com/dotnet/aspnetcore/blob/main/src/SignalR/clients/ts/signalr/src/HubConnection.ts @microsoft/signalr/HubConnection}
 */
export class MockHubConnection {
    handlers: Map<string, (...args: unknown[]) => void> = new Map();

    start(): void {
        this.handlers = new Map();
        addEventListener('MockHubConnectionEvent', this.customEventListener);
    }
    stop(): void {
        this.handlers.clear();
        removeEventListener('MockHubConnectionEvent', this.customEventListener);
    }
    invoke(): Promise<void> {
        return Promise.resolve();
    }

    on(eventName: string, callback: (...args: unknown[]) => void): void {
        this.handlers.set(eventName, callback);
    }

    off(methodName: string): void {
        this.handlers.delete(methodName);
    }

    /**
     * Needs eventName and payload in event.data to work
     * @param event
     */
    customEventListener = (event: CustomEvent): void => {
        // eslint-disable-next-line no-console
        console.log('mock signalR event received', event);

        const callback = this.handlers.get(event.detail.eventName);
        callback(event.detail.payload);
    };
}

export class TestSignalRHubService {
    public createConnection(): MockHubConnection {
        return new MockHubConnection();
    }
}
