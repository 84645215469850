import { UINotificationService } from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { ApiService, BFHttpError, IApiOptions } from '@core/services/api/api.service';
import { SessionService } from '@core/services/internal/session.service';
import { CreativeSet } from '@shared/models/studio/creativeset.model';
import { Campaign } from '@shared/models/campaign/models/campaign.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsApiService {
    constructor(
        private readonly sessionService: SessionService,
        private readonly apiService: ApiService,
        private readonly notificationService: UINotificationService
    ) {}

    private getDataApiUrl(customBrandSlug?: string): string {
        return `${AppConfig.config.origins.ANALYTICS_BACKEND_SERVICE_URL}/api/v2/${this.sessionService.user.account.slug}/${customBrandSlug || this.sessionService.user.brand.slug}/data`;
    }

    private getCampaignUrl(): string {
        return `${AppConfig.config.origins.ANALYTICS_BACKEND_SERVICE_URL}/api/v2/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/campaign`;
    }

    public async getCreativeSetsByIds(creativeSetIds: string[], customBrandSlug?: string): Promise<CreativeSet[]> {
        const options: IApiOptions = {
            cache: true,
            anonymous: true,
            errorNotification: true
        };

        return this.apiService.post(`${this.getDataApiUrl(customBrandSlug)}/creativesetsbyid`, creativeSetIds, options);
    }

    private handleError(err: any): void {
        const error: BFHttpError = err;
        this.notificationService.open('Something went wrong!', {
            type: 'error',
            placement: 'top',
            icon: 'close',
            autoCloseDelay: 5000
        });

        throw error;
    }

    public async getCampaign(campaignId: string): Promise<Campaign> {
        try {
            const response: any = await this.apiService.get(`${this.getCampaignUrl()}/${campaignId}`);

            return new Campaign().deserialize(response);
        } catch (error) {
            this.handleError(error);
        }
    }
}
