import { HttpInterceptorFn } from '@angular/common/http';
import { CookieService } from '@core/services/internal/cookie.service';
import { authHttpInterceptorFn } from '@auth0/auth0-angular';

export const proxyAuthInterceptorFn: HttpInterceptorFn = (req, next) => {
    if (CookieService.get('IN_TEST')) {
        return next(req);
    }
    return authHttpInterceptorFn(req, next);
};
