import { Serializable } from '@shared/interfaces/serializable.interface';

export class Schedule implements Serializable<Schedule> {
    public id: string;
    public slug: string;
    public name: string;
    public thumb: string;
    public folder: string;
    public placeholders: any[];

    public deserialize(json: any): Schedule {
        if (!json) {
            return null;
        }

        this.id = json.id;
        this.slug = json.slug;
        this.name = json.name;
        this.thumb = json.thumb;
        this.folder = json.folder;
        this.placeholders = json.placeholders;

        return this;
    }
}
