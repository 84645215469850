import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from '@core/services/internal/session.service';
import { User } from '@shared/models/user.model';

@Component({
    selector: 'profileImage',
    templateUrl: './profileImage.component.html',
    styleUrls: ['./profileImage.component.scss']
})
export class ProfileImageComponent implements OnInit {
    @Input() public user: User;
    @Input() public showArrow: boolean;
    @Input() public generateUnknown = false;
    @Input() public small = false;

    public initials: string;
    public userColor = '#9b9b9b';

    private colors: string[] = ['#2CBC45', '#efcf00', '#f65d77', '#6c4a80', '#1B75DD', '#404040', '#2fa8ca', '#9a523a'];

    public image: string;

    constructor(private sessionService: SessionService) {}

    public ngOnInit(): void {
        if (!this.generateUnknown) {
            const user: User = this.user || this.sessionService.user;

            if (user.firstName && user.lastName) {
                this.initials = user.firstName[0] + user.lastName[0];
            } else if (!user.firstName || !user.lastName) {
                this.initials = user.email[0] + user.email[1];
            }

            this.initials = this.initials.toUpperCase();

            const index: number = this.hashCode(user.email) % this.colors.length;
            this.userColor = this.colors[index];
        } else {
            this.initials = '?';
        }
    }

    // Generate random color for the profile image
    private hashCode(inputString: string): number {
        let hash = 0;

        if (inputString.length) {
            for (let i = 0; i < inputString.length; i++) {
                const char: number = inputString.charCodeAt(i);
                hash = hash + char;
            }
        }

        return hash;
    }
}
