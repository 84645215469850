import { CommonModule, DatePipe, I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BannerComponent } from './components/banner/banner.component';
import { ApproveDialogComponent } from './components/comment/approveDialog/approveDialog.component';
import { CommentInputComponent } from './components/comment/commentInput.component';
import { CommentsComponent } from './components/comment/comments.component';
import { CommentsService } from './components/comment/comments.service';
import { BFDropDownComponent } from './components/dropDown/bfDropDown.component';
import { HeaderContentBrandPickerComponent } from './components/headerContent/brandPicker/brandPicker.component';
import { EnvironmentPickerComponent } from './components/headerContent/environment-picker/environment-picker.component';
import { HeaderContentComponent } from './components/headerContent/headerContent.component';
import { BannerFormatDropDownComponent } from './components/objectPicker/bannerformat/bannerformat.dropdown.component';
import { BannerFormatPickerComponent } from './components/objectPicker/bannerformat/bannerformat.picker.component';
import { BannerSetDropDownComponent } from './components/objectPicker/bannerset/bannerset.dropdown.component';
import { BannerSetPickerComponent } from './components/objectPicker/bannerset/bannerset.picker.component';
import { BrandDropDownComponent } from './components/objectPicker/brand/brand.dropdown.component';
import { BrandPickerComponent } from './components/objectPicker/brand/brand.picker.component';
import { CampaignDropDownComponent } from './components/objectPicker/campaign/campaign.dropdown.component';
import { CampaignPickerComponent } from './components/objectPicker/campaign/campaign.picker.component';
import { LandingPageDropDownComponent } from './components/objectPicker/landingpage/landingpage.dropdown.component';
import { LandingPagePickerComponent } from './components/objectPicker/landingpage/landingpage.picker.component';
import { LocalizationDropDownComponent } from './components/objectPicker/localization/localization.dropdown.component';
import { LocalizationPickerComponent } from './components/objectPicker/localization/localization.picker.component';
import { ObjectDropDownComponent } from './components/objectPicker/objectDropDown.component';
import { ObjectPickerComponent } from './components/objectPicker/objectPicker.component';
import { ReportingLabelComponent } from './components/objectPicker/reporting-label/reporting.label.component';
import { ReportingPickerComponent } from './components/objectPicker/reporting-label/reporting.label.picker.component';
import { ProfileImageComponent } from './components/profileImage/profileImage.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { WelcomePanelComponent } from './components/welcomePanel/welcomePanel.component';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { DebounceInputDirective } from './directives/debounceInput.directive';
import { InViewDirective } from './directives/inView.directive';
import { NavigatorLinkDirective } from './directives/navigatorLink.directive';
import { NumbersOnlyDirective } from './directives/numbersOnly.directive';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ToSecondsPipe } from './pipes/toSeconds.pipe';
import { ToTimeAgoPipe } from './pipes/toTimeAgo.pipe';

@NgModule({
    imports: [CommonModule, UIModule, FormsModule, RouterModule, NgxDatatableModule, EllipsisPipe],
    declarations: [
        // COMPONENTS
        ApproveDialogComponent,
        BFDropDownComponent,
        BannerComponent,
        BannerFormatDropDownComponent,
        BannerFormatPickerComponent,
        BannerSetDropDownComponent,
        BannerSetPickerComponent,
        BrandDropDownComponent,
        BrandPickerComponent,
        CampaignPickerComponent,
        CampaignDropDownComponent,
        CommentInputComponent,
        CommentsComponent,
        HeaderContentComponent,
        HeaderContentBrandPickerComponent,
        LandingPageDropDownComponent,
        LandingPagePickerComponent,
        LocalizationDropDownComponent,
        LocalizationPickerComponent,
        ObjectDropDownComponent,
        ObjectPickerComponent,
        ProfileImageComponent,
        RadioGroupComponent,
        SidebarComponent,
        WelcomePanelComponent,
        EnvironmentPickerComponent,
        ReportingLabelComponent,
        ReportingPickerComponent,

        // DIRECTIVES
        ClickOutsideDirective,
        DebounceInputDirective,
        InViewDirective,
        NumbersOnlyDirective,
        NavigatorLinkDirective,

        // PIPES
        CapitalizePipe,
        ToSecondsPipe,
        ToTimeAgoPipe,
    ],
    exports: [
        // MODULES
        UIModule,
        // COMPONENTS
        BFDropDownComponent,
        BannerComponent,
        BannerFormatDropDownComponent,
        BannerFormatPickerComponent,
        BannerSetDropDownComponent,
        BannerSetPickerComponent,
        BrandDropDownComponent,
        BrandPickerComponent,
        CampaignPickerComponent,
        CampaignDropDownComponent,
        CommentsComponent,
        CommentInputComponent,
        HeaderContentComponent,
        HeaderContentBrandPickerComponent,
        LandingPageDropDownComponent,
        LandingPagePickerComponent,
        LocalizationDropDownComponent,
        LocalizationPickerComponent,
        ObjectPickerComponent,
        ObjectDropDownComponent,
        RadioGroupComponent,
        SidebarComponent,
        ProfileImageComponent,
        WelcomePanelComponent,
        EnvironmentPickerComponent,
        ReportingLabelComponent,
        ReportingPickerComponent,

        // DIRECTIVES
        DebounceInputDirective,
        InViewDirective,
        NavigatorLinkDirective,
        NumbersOnlyDirective,
        ClickOutsideDirective,
        // PIPES
        CapitalizePipe,
        ToSecondsPipe,
        ToTimeAgoPipe
    ],
    providers: [
        // Angular pipes
        DatePipe,
        I18nPluralPipe,
        CommentsService,

        // PIPES
        CapitalizePipe,
        EllipsisPipe,
        ToSecondsPipe,
        ToTimeAgoPipe
    ]
})
export class SharedModule {}
